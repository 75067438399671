<template>
  <div>
    <div class="row d-flex mb-2">
      <div class="col-sm col-12">
        <input
          v-model="agendaSurat.filter"
          class="form-control"
          placeholder="Cari No Agenda, Tujuan, Perihal"
        />
      </div>
      <div
        class="col-sm-3 text-right ml-auto"
        v-if="isRolesAdminGovermentGetters"
      >
        <el-select
          v-model="agendaSurat.filterCreatorId"
          aria-autocomplete="none"
          class="w-100"
          placeholder="Pilih Petugas"
          @change="getAgendaSurat({ page: 1 })"
        >
          <el-option label="Semua Data" value=""></el-option>
          <el-option
            v-for="(item, index) in employee.itemsAll"
            :key="`petugas2_${index}`"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="col-sm-4 text-right ml-auto">
        <div class="clearfix">
          <div class="float-right">
            <el-select
              v-model="agendaSurat.limit"
              aria-autocomplete="none"
              class="w-50"
              filterable
              placeholder="Tampilkan Data"
              @change="getAgendaSurat({ page: 1 })"
            >
              <el-option
                v-for="item in agendaSurat.listLimit"
                :key="`limit_${item}`"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="agendaSurat.filterYear"
              aria-autocomplete="none"
              class="w-50"
              placeholder="Pilih Tahun"
              @change="getAgendaSurat({ page: 1 })"
            >
              <el-option
                v-for="item in yearComputed"
                :key="`year_${item}`"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <LabelCountData
        :count-items-all="agendaSurat.total"
        :count-items="agendaSurat.items.length"
      />
      <PaginationData
        :current-page="agendaSurat.page"
        :last-page="agendaSurat.lastPage"
        @change-nextpage="
          onNextPageEventHandler(agendaSurat.page, getAgendaSurat)
        "
        @change-prevpage="
          onPrevPageEventHandler(agendaSurat.page, getAgendaSurat)
        "
      />
    </div>

    <div class="row mt-n2 ml-0 mr-0">
      <b-table
        :busy="agendaSurat.loading"
        :fields="fields"
        :items="agendaSurat.items"
        :no-border-collapse="noCollapse"
        :sticky-header="true"
        class="table-kertas-kerja"
        :sort-by.sync="agendaSurat.sort_field"
        :sort-desc.sync="agendaSurat.sort_dir"
        hover
        responsive
        show-empty
        striped
      >
        <template #table-busy>
          <EfekLoading />
        </template>

        <template #empty>
          <LabelEmpty />
        </template>

        <template #cell(no)="data">
          <span>{{
            (agendaSurat.page - 1) * agendaSurat.limit + data.index + 1
          }}</span>
        </template>

        <template #cell(agenda_number)="data">
          <span
            >{{ data.item.agenda_number }}
            <i
              class="fas fa-link cursor-pointer"
              @click="copyTeksInput(data.item.agenda_number)"
            ></i
          ></span>
        </template>

        <template #cell(date)="data">
          <span>
            {{ data.item.date | formatDateTimeDayIndonesia }}
          </span>
        </template>

        <template #cell(purpose)="data">
          <span
            class="inline"
            v-if="data.item.school"
          >
          <i
            v-b-tooltip.hover
            :title="'dibagikan ke '+data.item.school.name.substring(0, 25)"
            class="fas fa-share-alt"></i> &nbsp;
          </span>
          <span> {{ data.item.purpose }}</span>
        </template>

        <template #cell(subject)="data">
          <p style="width: 500px;white-space: break-spaces;">
            {{ data.item.subject }}
          </p>
        </template>

        <template #cell(officer)="data">
          <span>{{ data.item.officer }}</span>
        </template>

        <template #cell(department)="data">
          <span>{{ data.item.department }}</span>
        </template>

        <template v-slot:cell(aksi)="data">
          <button
            v-b-tooltip.hover
            class="btn btn-warning btn-sm text-white"
            title="Edit Data"
            @click="editData(data.item)"
          >
            <i class="fas fa-pencil-alt"></i>
          </button>
          <button
            v-b-tooltip.hover
            class="btn btn-danger btn-sm text-white"
            title="Hapus Data"
            @click="deleteData(data.item.id)"
          >
            <i class="fas fa-trash"></i>
          </button>
          <!---->
          <button
            v-b-tooltip.hover
            class="btn btn-dark btn-sm text-white"
            title="Upload Berkas"
            @click="showModalUploadFile(data.item)"
          >
            <i class="fas fa-upload"></i>
          </button>
          <a
            v-if="data.item.file"
            target="_blank"
            :href="data.item.file"
            v-b-tooltip.hover
            class="btn btn-info btn-sm text-white"
            title="Lihat Berkas"
          >
            <i class="fas fa-eye"></i>
          </a>
        </template>
      </b-table>
    </div>

    <Modal
      :onClose="() => (agendaSurat.showmodal = false)"
      :show="agendaSurat.showmodal"
      :title="
        agendaSurat.formData.id
          ? `Proses Update (Perubahan Data Agenda Surat)`
          : `Tambah Data Agenda Surat`
      "
      width="75%"
    >
      <ModalDetail
        v-if="agendaSurat.showmodal"
        :onClose="() => (agendaSurat.showmodal = false)"
        @closeModal="() => (agendaSurat.showmodal = false)"
      />
    </Modal>

    <Modal
      :onClose="() => (showmodalUploadFile = false)"
      :show="showmodalUploadFile"
      title="Upload Berkas"
      width="75%"
    >
      <ModalDetail
        v-if="showmodalUploadFile"
        jenis-modal="upload"
        :onClose="() => (showmodalUploadFile = false)"
        @closeModal="() => (showmodalUploadFile = false)"
      />
    </Modal>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { copyTeksInput } from "@/utils/Api";
import moment from "moment";
import { _ } from "vue-underscore";
import PaginationData from "@/components/Table/PaginationData.vue";
import LabelCountData from "@/components/Table/LabelCountData.vue";
import {
  onNextPageEventHandler,
  onPrevPageEventHandler
} from "@/services/internalGlobalService";
import LabelEmpty from "@/components/Table/LabelEmpty.vue";
import EfekLoading from "@/components/Table/EfekLoading.vue";

export default {
  name: "TableAgendaSurat",
  props: {
    searchSchoolType: {
      type: String,
      required: false
    },
    search: {
      type: String,
      required: false
    }
  },
  components: {
    EfekLoading,
    LabelEmpty,
    LabelCountData,
    PaginationData,
    Modal: () => import("@/components/ModalUI"),
    ModalDetail: () => import("./Modal/index.vue")
  },
  computed: {
    ...mapState({
      schoolsAll: state => state.schools.data.itemsAll,
      agendaSurat: state => state.agendaSurat.data,
      employee: state => state.employee.data
    }),
    ...mapGetters("employee", ["employeeGetters"]),
    ...mapGetters(["govermentIdGetters", "isRolesAdminGovermentGetters"]),

    yearComputed() {
      const currentYear = moment().year(); // Mendapatkan tahun saat ini
      const startYear = 2019;
      let years = [];

      for (let year = startYear; year <= currentYear; year++) {
        years.push(year);
      }

      return years;
    }
  },
  data() {
    return {
      fields: [
        {
          key: "no",
          sortable: false,
          thStyle: {
            width: "5%"
          }
        },
        {
          label: "No Agenda",
          key: "agenda_number",
          sortable: true,
          thStyle: {
            width: "10%"
          }
        },
        {
          label: "Tanggal",
          key: "date",
          sortable: true
        },
        {
          label: "Tujuan",
          key: "purpose",
          sortable: false
        },
        {
          label: "Perihal",
          key: "subject",
          sortable: false
        },
        {
          label: "Petugas",
          key: "officer",
          sortable: false,
          thStyle: {
            width: "20%"
          }
        },
        {
          label: "Cabang",
          key: "department",
          sortable: false,
          thStyle: {
            width: "20%"
          }
        },
        {
          key: "aksi",
          sortable: false,
          thStyle: {
            width: "10%"
          }
        }
      ],
      stickyHeader: true,
      noCollapse: true,
      showmodal: false,
      showmodalUploadFile: false
    };
  },
  beforeDestroy() {
    this.$store.commit("schools/changeSchools", {
      filterCity: ""
    });
  },
  methods: {
    onPrevPageEventHandler,
    onNextPageEventHandler,
    ...mapActions("agendaSurat", [
      "getAgendaSurat",
      "deleteAgendaSurat",
      "getPurposeAll",
      "actionRead",
      "actionEdit",
      "actionCreate",
      "actionUpload"
    ]),
    ...mapActions("employee", ["getEmployeeRoleAdmin"]),
    ...mapMutations("agendaSurat", [
      "changeFormDataAgendaSurat",
      "changeAgendaSurat"
    ]),

    copyTeksInput,
    handlerFormData(row) {
      return {
        id: row.id,
        agenda_number: row.agenda_number,
        date: row.date,
        school: row.school,
        officer: row.officer,
        purpose: row.purpose,
        subject: row.subject
      };
    },

    showModalUploadFile(row) {
      // call schoolsAllByCity
      const cityUuid = this.$store.getters["cityUuidAdminGetters"];
      this.$store.commit("schools/changeSchools", {
        filterCity: cityUuid
      });
      if (!this.schoolsAll.length) {
        this.$store.dispatch("schools/getSchoolsAllByCity");
      }
      this.changeFormDataAgendaSurat(this.handlerFormData(row));

      const payload2 = { detailFormData: row };
      this.changeAgendaSurat(payload2);

      this.actionUpload();
      this.showmodalUploadFile = true;
    },
    deleteData(id) {
      this.$confirm("Anda yakin menghapus Agenda ini ?", "Konfirmasi", {
        confirmButtonText: "Ya",
        cancelButtonText: "Batalkan",
        type: "warning"
      })
        .then(async () => {
          await this.deleteAgendaSurat({ id: id });
        })
        .catch(e => e);
    },
    editData(row) {
      const payload2 = { detailFormData: row, showmodal: true };
      this.changeFormDataAgendaSurat(this.handlerFormData(row));
      this.changeAgendaSurat(payload2);
      this.actionEdit();
    }
  },
  mounted() {
    this.actionRead();
    if (!this.employee.itemsAll.length) {
      this.getEmployeeRoleAdmin({ id: this.govermentIdGetters });
    }
    if (!this.agendaSurat.itemsPurposeAll.length) {
      this.getPurposeAll();
    }
  },
  watch: {
    "agendaSurat.filter": _.debounce(function() {
      this.getAgendaSurat({ page: 1 });
    }, 1000),
    "agendaSurat.sort_dir": _.debounce(function() {
      this.getAgendaSurat();
    }, 1000)
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/custom/_rkas-table.scss";
</style>
